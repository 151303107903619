import styled from "styled-components";
import { Wrapper } from "../Grid";

export const PhotovoltaicsUseBlockWrapper = styled(Wrapper)`
  scroll-margin-top: 32px;
`;

export const UsageBlockSmallHeading = styled.h4`
  width: 100%;
  flex-grow: 0;
`;
