import styled from "styled-components";
import propTypes from "@styled-system/prop-types";

import { Flex } from "../Grid";

const FAQQuestion = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  color: ${props => props.theme.colors.exploratoryGreen};

  p {
    line-height: 1.5;
    font-weight: 600;
  }

  svg {
    min-width: 20px;
    min-height: 20px;
    margin-left: 16px;
    transition: all 300ms;

    transform: ${props => (props.open ? "rotate(180deg)" : "none")};
  }
`;

FAQQuestion.displayName = "FAQQuestion";

FAQQuestion.defaultProps = {};

FAQQuestion.propTypes = {
  ...propTypes.flexbox,
  ...propTypes.position,
};

export default FAQQuestion;
