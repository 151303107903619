import React from "react";
import accounting from "accounting";
import { PhotovoltaicsFormItem } from "./photovoltaicsFormItem";
import { useSolarCalculatorForm } from "../../hooks/useSolarCalculatorForm";

const MEGAWATT_HOURS_UNIT = "MWh";
const KILOWATT_PEAK_UNIT = "kWp";

const EXAMPLE_PHOTOVOLTAIC_POWER_PLACEHOLDER = "10";
const EXAMPLE_ANNUAL_ELECTRICITY_PRODUCTION_PLACEHOLDER = "9,5";
const EXAMPLE_ANNUAL_ELECTRICITY_CONSUMPTION_PLACEHOLDER = "6";
const EXAMPLE_ANNUAL_ELECTRICITY_EXCESS_PLACEHOLDER = "3,5";
const EXAMPLE_SOLAR_ACCOUNT_EARNINGS_PRICE = 4180;
const DEFAULT_PLACEHOLDER = "0";

export const PhotovoltaicsCalculatorBlock = ({
  title,
  photovoltaicsPowerLabel,
  annualElectricityProductionEstimateLabel,
  annualElectricityConsumptionLabel,
  annualElectricityExcessLabel,
  expectedAnnualAdvanceLabel,
  solarAccountEarningsPrice,
  monthlyFee,
}) => {
  const {
    annualElectricityConsumption,
    annualElectricityExcess,
    annualElectricityProductionEstimate,
    expectedAnnualEarnings,
    handleAnnualElectricityConsumption,
    handleAnnualElectricityExcess,
    handlePhotovoltaicsPowerChange,
    photovoltaicsPower,
    photovoltaicsPowerErrorMessage,
    hasUserEditAnyInput,
  } = useSolarCalculatorForm(solarAccountEarningsPrice, monthlyFee);

  return (
    <div className="max-w-[755px] w-full px-5">
      <div className="block sm:hidden text-center mb-8">
        <img
          className="mb-0 drop-shadow-[4px_4px_10px_rgba(0,0,0,0.3)]"
          src={require("../../images/calculator.png").default}
          width={98}
          height={180}
          alt="Kalkulačka"
        />
      </div>

      <h3
        className="mb-8 text-exploratory-green-400 text-center sm:max-w-[420px] max-w-[285px] mx-auto text-H4 sm:text-H3"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h3>

      <div className="flex gap-[70px]">
        <div className="hidden sm:block shrink-0">
          <img
            className="mb-0 drop-shadow-[4px_4px_10px_rgba(0,0,0,0.3)]"
            src={require("../../images/calculator.png").default}
            width={163}
            height={299}
            alt="Kalkulačka"
          />
        </div>
        <div className="flex flex-col gap-6 text-exploratory-green-400 w-full">
          <div className="bg-white w-full h-px"></div>

          <div className="flex gap-2 flex-col">
            <PhotovoltaicsFormItem
              label={photovoltaicsPowerLabel}
              units={KILOWATT_PEAK_UNIT}
              value={photovoltaicsPower}
              onChange={handlePhotovoltaicsPowerChange}
              error={photovoltaicsPowerErrorMessage}
              placeholder={hasUserEditAnyInput ? DEFAULT_PLACEHOLDER : EXAMPLE_PHOTOVOLTAIC_POWER_PLACEHOLDER}
            />
            <PhotovoltaicsFormItem
              label={annualElectricityProductionEstimateLabel}
              units={MEGAWATT_HOURS_UNIT}
              value={annualElectricityProductionEstimate}
              isDisabled
              placeholder={hasUserEditAnyInput ? DEFAULT_PLACEHOLDER : EXAMPLE_ANNUAL_ELECTRICITY_PRODUCTION_PLACEHOLDER}
              tooltipText={
                "Odhad vychází z převodníku 1 kWp výkonu = 0,95 MWh/rok. Skutečná výroba je ovlivněna sklonem a umístěním panelů, stavem a stářím fotovoltaiky a počtem slunečných dní."
              }
            />
          </div>

          <div className="flex gap-2 flex-col">
            <PhotovoltaicsFormItem
              label={annualElectricityConsumptionLabel}
              units={MEGAWATT_HOURS_UNIT}
              value={annualElectricityConsumption}
              onChange={handleAnnualElectricityConsumption}
              placeholder={hasUserEditAnyInput ? DEFAULT_PLACEHOLDER : EXAMPLE_ANNUAL_ELECTRICITY_CONSUMPTION_PLACEHOLDER}
            />
            <PhotovoltaicsFormItem
              label={annualElectricityExcessLabel}
              units={MEGAWATT_HOURS_UNIT}
              value={annualElectricityExcess}
              onChange={handleAnnualElectricityExcess}
              placeholder={hasUserEditAnyInput ? DEFAULT_PLACEHOLDER : EXAMPLE_ANNUAL_ELECTRICITY_EXCESS_PLACEHOLDER}
            />
          </div>

          <div className="bg-white h-px w-full"></div>

          <div className="font-bold flex gap-4 justify-between">
            <div className="max-w-[150px] sm:max-w-full text-mediumForms sm:text-bigForms">{expectedAnnualAdvanceLabel}</div>
            <div className="text-xl shrink-0">
              {photovoltaicsPowerErrorMessage
                ? ""
                : accounting.formatMoney(hasUserEditAnyInput ? expectedAnnualEarnings : EXAMPLE_SOLAR_ACCOUNT_EARNINGS_PRICE, "", 0, " ", ",") + " Kč"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
