import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { Flex } from "../Grid";

const FAQItemStyle = styled(Flex)`
  flex-direction: column;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.102);
  background-color: white;

  transition: all 250ms ease-in-out;
  height: 100%;

  max-height: ${props => (props.open ? `${props.maxHeight + 40}px` : "90px")};

  margin-bottom: ${props => (props.open ? "40px" : "16px")};

  @media screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: ${props => (props.open ? "24px" : "8px")};
    max-height: ${props => (props.open ? `${props.maxHeight + 40}px` : "150px")};
  }

  a {
    &,
    &:link,
    &:visited {
      color: ${props => props.theme.colors.exploratoryGreen};
    }

    &:hover {
      text-decoration: none;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

FAQItemStyle.displayName = "FAQItemStyle";

FAQItemStyle.defaultProps = {
  py: 20,
  px: 22,
};

FAQItemStyle.propTypes = {
  ...propTypes.flexbox,
  ...propTypes.position,
};

export default FAQItemStyle;
