import React from "react";

const DEFAULT_SIZE = 22;

const CheckIcon = ({ width, height }) => (
  <svg
    width={`${width || DEFAULT_SIZE}`}
    height={`${height || DEFAULT_SIZE}`}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.77934 16.5258L18.6948 6.61033L17.1455 5.00939L8.77934 13.3756L4.85446 9.4507L3.30516 11L8.77934 16.5258ZM3.20188 3.25352C5.37089 1.08451 7.97027 0 11 0C14.0297 0 16.6119 1.08451 18.7465 3.25352C20.9155 5.38811 22 7.97027 22 11C22 14.0297 20.9155 16.6291 18.7465 18.7981C16.6119 20.9327 14.0297 22 11 22C7.97027 22 5.37089 20.9327 3.20188 18.7981C1.06729 16.6291 0 14.0297 0 11C0 7.97027 1.06729 5.38811 3.20188 3.25352Z"
      fill="#A8BE32"
    />
  </svg>
);

export default CheckIcon;
