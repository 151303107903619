import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { Box } from "../Grid";

const FAQAnswer = styled(Box)`
  height: 100%;

  transition: max-height 300ms, all 250ms;

  max-height: ${props => (props.open ? `${props.maxHeight}px` : "0")};

  overflow: hidden;

  p {
    color: ${props => props.theme.colors.expertiseGray};

    font-size: 15px;
    line-height: 1.6;

    margin-top: 8px;

    &:first-of-type {
      margin-top: 28px;
    }

    margin-bottom: 0;

    span {
      color: ${props => props.theme.colors.exploratoryGreen};
    }

    ol,
    ul {
      margin-top: 8px;
    }

    ol {
      counter-reset: list;

      & > li {
        list-style: none;
        position: relative;

        &::before {
          counter-increment: list;
          content: counter(list, lower-alpha) ") ";
          position: absolute;
          left: -1.4em;
        }
      }
    }
  }
`;

FAQAnswer.displayName = "FAQAnswer";

FAQAnswer.defaultProps = {};

FAQAnswer.propTypes = {
  ...propTypes.flexbox,
  ...propTypes.position,
};

export default FAQAnswer;
