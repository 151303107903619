import React from "react";
import cx from "classnames";
import CheckIcon from "../../style/icons/check";
import { Text } from "../../style/Text";
import { Box } from "../../style/Grid";
import { MarkupToHtml } from "../../helpers/parse";
import { SolarPanelIconsWrapper, SolarPanelIconWrapper } from "../../style/Photovoltaics/PhotovoltaicsInfoBlock";

const CHECK_ICON_SIZE = 16;

const solarPanelUseIconByItemEnum = {
  'billing': <img src={require('../../images/fve-alt.png').default} className="mb-0" width="62.5px" height="40px" alt="vyúčtování" />,
  'supply_point': <img src={require('../../images/pay-another-supply-point.png').default} className="mb-0" width="75px" height="40px" alt="zálohy" />,
  'voucher': <img src={require('../../images/pay-voucher.png').default} className="mb-0" width="81px" height="40px" alt="poukaz" />,
};

export const PhotovoltaicsInfoBlock = ({ title, text, listItems, tableRows, icon, iconWidth, iconHeight, bottomText, bottomTextColor, solarPanelUse, iconsScrollTargetRef, tableInfoText }) =>
{

  const handleIconContainerClick = () =>
  {
    if (!iconsScrollTargetRef?.current)
    {
      return;
    }

    iconsScrollTargetRef.current.scrollIntoView();
  }

  return (<>
    <div className="flex gap-4 items-center h-[72px]">
      {icon ? <img className="mb-0" src={icon} width={iconWidth} height={iconHeight} alt="Solární účet" /> : null}
      <h3 className="text-exploratory-green-400 text-H3 mb-0"><MarkupToHtml>{title}</MarkupToHtml></h3>
    </div>
    <Text fontWeight={500} fontSize={["16px"]} lineHeight={["24px"]} mt={"20px"}>
      <MarkupToHtml>{text}</MarkupToHtml>
    </Text>
    {listItems ? (
      <div className="flex flex-col mt-6 gap-2">
        {listItems.map((listItem, index) => (
          <div key={index} className="flex gap-4 flex-row max-w-[380px]">
            <Box mt={"4px"}>
              <CheckIcon width={CHECK_ICON_SIZE} height={CHECK_ICON_SIZE} />
            </Box>
            <div className="text-exploratory-green-400">
              <Text fontWeight={500} fontSize={["16px"]} lineHeight={["24px"]} mb={"10px"}>
                <MarkupToHtml>{listItem.content}</MarkupToHtml>
              </Text>
            </div>
          </div>
        ))}
      </div>
    ) : null}
    {tableRows?.length ? (
      <div className="mt-7">
        <div className={"grid text-center gap-px" + (tableRows.length > 2 ? " grid-cols-3" : " grid-cols-2")}>
          {tableRows.map(({ title, text }, index) =>
          {
            const isLastCol = index === tableRows.length - 1;

            return (
              <div className={cx("text-white p-2", {
                "bg-[#ACAEB0]": isLastCol,
                "bg-resource-green-400": !isLastCol
              })
              } key={index}>
                <div className="text-bigForms font-bold leading-[23px]">
                  <MarkupToHtml>{title}</MarkupToHtml>
                </div>
                <div className="text-small">
                  <MarkupToHtml>{text}</MarkupToHtml>
                </div>
              </div>
            )
          })}
          {tableRows.map(({ value }, index) =>
          {
            const isLastCol = index === tableRows.length - 1;

            return (
              <div
                className={cx("font-bold p-4", {
                  "text-expertise-gray": isLastCol,
                  "bg-light-grey": isLastCol,
                  "text-exploratory-green-400": !isLastCol,
                  "bg-[#A8BE3226]": !isLastCol
                })}
              >{value}</div>
            )
          })}
        </div>
        <div className="text-right mt-2 text-textGrey text-[14px]">{tableInfoText}</div>
      </div >
    ) : null}

    {
      bottomText ? (
        <Text fontWeight={500} fontSize={["16px"]} color={bottomTextColor} lineHeight={["24px"]} mt={"10px"}>
          <MarkupToHtml>{bottomText}</MarkupToHtml>
        </Text>
      ) : null
    }

    {
      solarPanelUse ? (
        <SolarPanelIconsWrapper className="flex mt-6 mb-4 flex-wrap gap-1" onClick={handleIconContainerClick}>
          {solarPanelUse.map((solarPanelUseItem, index) => (
            <SolarPanelIconWrapper key={index} className="flex flex-col gap-[12px] flex-1 items-center">
              {solarPanelUseIconByItemEnum[solarPanelUseItem?.icon]}
              <div className="font-bold text-exploratory-green-400 text-center">
                {solarPanelUseItem?.title || ''}
              </div>
            </SolarPanelIconWrapper>
          ))}
        </SolarPanelIconsWrapper>
      ) : null
    }
  </>);
};
