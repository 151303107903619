import styled from "styled-components";

export const VizualCustomImage = styled.div`
  width: ${props => props.width || "40.25%"};
  height: 100%;
  background-image: ${props => props.backgroundImage};
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: ${props => props.theme.breakpoints.lg}) {
    background-position: center;
  }
`;
