import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import MainLayout from "../componentsTailwind/MainLayout";
import ContactForm from "../components/contactForm";
import LeadMaintenance from "../components/LeadMaintenance";
import MainVizualLP from "../components/MainVizualLP";
import { SemiVizualLP } from "../components/SemiVizualLP";
import { FAQItem } from "../components/FAQ/FAQItem";
import { Flex, Wrapper, Container } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { getCommodityFromHash } from "../helpers";
import { MarkupToHtml } from "../helpers/parse";
import { DoubleBlock } from "../components/doubleBlock";
import { PhotovoltaicsInfoBlock } from "../components/Photovoltaics/photovoltaicsInfoBlock";
import { PhotovoltaicsAccountRightBlock } from "../components/Photovoltaics/photovoltaicsAccountRightBlock";
import { PhotovoltaicsAccountLeftBlock } from "../components/Photovoltaics/photovoltaicsAccountLeftBlock";
import { PhotovoltaicsCalculatorBlock } from "../components/Photovoltaics/photovoltaicsCalculatorBlock";
import { theme } from "../style/theme";
import { PhotovoltaicsUseBlock } from "../components/Photovoltaics/photovoltaicsUseBlock";
import { PhotovoltaicsUseBlockWrapper } from "../style/Photovoltaics/PhotovoltaicsUseBlock";

const RedemptionSolarEnergy = ({ data }) =>
{

  const blockUseWrapperRef = useRef(null);

  const {
    mnd: {
      redemptionSolarEnergy: {
        data: {
          attributes: {
            SEO: { title, description, keywords },
            MainVizualLP: { title: vizualTitle, text, buttonTitle, buttonLink },
            CtaVizualLP,
            FAQ: { title: faqSectionTitle, questions: FAQs },
            solarInfoLeftPanel,
            solarInfoRightPanel,
            solarAccountBlockTitle,
            solarAccountLeftPanel,
            solarAccountRightPanel,
            solarCalculatorBlock,
            SolarUseBlock,
          },
        },
      },
    },
  } = data;

  return (
    <MainLayout>
      <Helmet
        htmlAttributes={{
          lang: "cs",
        }}
      >
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Product', section: 'Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/vykup-elektriny-z-fotovoltaiky"></link>
      </Helmet>

      <MainVizualLP
        title={vizualTitle}
        text={text}
        buttonTitle={buttonTitle}
        buttonLink={buttonLink}
        backgroundImage={[
          `url(${require("../images/banner/hotspot-lp-banner-mobile.jpg").default})
        `,
          undefined,
          `url(${require("../images/banner/hotspot-lp-banner-desktop.jpg").default})`,
          `url(${require("../images/banner/hotspot-lp-banner-desktop@2x.jpg").default})`,
        ]}
      />

      <Wrapper background={theme.colors.grey.default} p={["0", "50px 0 11px"]} id="CoJeSolarniUcet">
        <DoubleBlock
          firstBlock={
            <PhotovoltaicsInfoBlock
              title={solarInfoLeftPanel.title}
              text={solarInfoLeftPanel.description}
              listItems={solarInfoLeftPanel.advantages}
              bottomText={solarInfoLeftPanel.bottomText}
              icon={require("../images/photovoltaics-account-icon.png").default}
              iconWidth={72}
              iconHeight={72}
            />
          }
          secondBlock={
            <PhotovoltaicsInfoBlock
              title={solarInfoRightPanel.title}
              text={solarInfoRightPanel.description}
              tableRows={solarInfoRightPanel.tableColumn.map(row => ({
                title: row.title,
                text: row.subtitle,
                value: row.value,
              }))}
              tableInfoText={solarInfoRightPanel.tableDescription}
              bottomText={solarInfoRightPanel.bottomText}
              solarPanelUse={solarInfoRightPanel.solarPanelUse}
              bottomTextColor={"exploratoryGreen"}
              iconWidth={100}
              iconHeight={64}
              iconsScrollTargetRef={blockUseWrapperRef}
            />
          }
        />
      </Wrapper>

      <Wrapper background={theme.colors.grey.default} p={["48px 0 0", "50px 0 11px"]} id="JakZiskatSolarniUcet">
        <DoubleBlock
          title={solarAccountBlockTitle}
          firstBlock={
            <PhotovoltaicsAccountLeftBlock
              title={solarAccountLeftPanel.title}
              hint={solarAccountLeftPanel.hint}
              leftButtonTitle={solarAccountLeftPanel.leftButtonTitle}
              leftButtonLink={solarAccountLeftPanel.leftButtonLink}
              rightButtonTitle={solarAccountLeftPanel.rightButtonTitle}
              rightButtonLink={solarAccountLeftPanel.rightButtonLink}
              priceLeftPanelOffers={solarAccountLeftPanel.priceOffersLeftPanel}
              priceRightPanelOffers={solarAccountLeftPanel.priceOffersRightPanel}
              priceOffersLeftPanelTitle={solarAccountLeftPanel.priceOffersLeftPanelTitle}
              priceOffersRightPanelTitle={solarAccountLeftPanel.priceOffersRightPanelTitle}
            />
          }
          secondBlock={
            <PhotovoltaicsAccountRightBlock
              title={solarAccountRightPanel.title}
              description={solarAccountRightPanel.description}
              leftButtonTitle={solarAccountRightPanel.leftButtonTitle}
              leftButtonLink={solarAccountRightPanel.leftButtonLink}
              rightButtonTitle={solarAccountRightPanel.rightButtonTitle}
              rightButtonLink={solarAccountRightPanel.rightButtonLink}
            />
          }
          firstBlockFlex={9}
          secondBlockFlex={4}
        />
      </Wrapper>

      <Wrapper position="relative" background={theme.colors.grey.default} p={["32px 0", "50px 0 72px"]} id="Kalkulacka">
        <PhotovoltaicsCalculatorBlock
          title={solarCalculatorBlock.title}
          photovoltaicsPowerLabel={solarCalculatorBlock.photovoltaicsPowerLabel}
          annualElectricityProductionEstimateLabel={solarCalculatorBlock.annualElectricityProductionEstimateLabel}
          annualElectricityConsumptionLabel={solarCalculatorBlock.annualElectricityConsumptionLabel}
          annualElectricityExcessLabel={solarCalculatorBlock.annualElectricityExcessLabel}
          expectedAnnualAdvanceLabel={solarCalculatorBlock.expectedAnnualAdvanceLabel}
          solarAccountEarningsPrice={solarCalculatorBlock.solarAccountEarningsPrice}
          monthlyFee={solarCalculatorBlock.monthlyFee}
        />
      </Wrapper>

      <PhotovoltaicsUseBlockWrapper background={theme.colors.grey.default} p={["32px 0", "56px 0 48px"]} ref={blockUseWrapperRef}>
        <PhotovoltaicsUseBlock
          title={SolarUseBlock?.title || ''}
          solarUseCards={SolarUseBlock?.SolarUseCards || []}
        />
      </PhotovoltaicsUseBlockWrapper>

      <div className="white.100 pb-14 mt-8" id="faq">
        <div className={"container mx-auto max-w-screen-lg px-4"}>
          <h3 className="text-H4 mb-5 md:mb-10 text-exploratory-green-400">
            <MarkupToHtml>{faqSectionTitle}</MarkupToHtml>
          </h3>
          {FAQs.map(({ question, answer }, index) => (
            <FAQItem key={index} question={question} answer={<Text>{answer}</Text>} />
          ))}
        </div>
      </div>

      {/* <SemiVizualLP
        title={CtaVizualLP.title}
        text={CtaVizualLP.text}
        buttonText={CtaVizualLP.buttonText}
        buttonHref={CtaVizualLP.buttonHref}
        linkText={CtaVizualLP.linkText}
        linkHref={CtaVizualLP.linkHref}
      /> */}

      {/* <Wrapper
        position="relative"
        background={["white.100", `#a7be32 url(${require("../images/contactForm__bg.jpg").default}) no-repeat bottom center`]}
        p={["30px 0", "60px 0 40px"]}
      >
        <Container id="contactForm">
          <LeadMaintenance />
          <Flex flexDirection="column" alignItems={["flex-start", "center"]} width={1} mb={[0, 50]}>
            <Heading as="h2" fontSize={["26px", null, "36px", null]} fontWeight="700" color={["green.dark", "white.100"]}>
              Získejte výhodné energie od MND
            </Heading>
          </Flex>
          <ContactForm
            enableCallbackTime={false}
            enableEmail={false}
            type="PAGE_HP"
            commodity={getCommodityFromHash(typeof document !== "undefined" ? document.location.hash : "")}
          />
        </Container>
      </Wrapper> */}
    </MainLayout>
  );
};

export const query = graphql`
  query RedemptionSolarEnergyQuery {
    mnd {
      redemptionSolarEnergy {
        data {
          attributes {
            SEO {
              title
              description
            }
            solarInfoLeftPanel {
              title
              description
              advantages {
                content
              }
              bottomText
            }
            solarInfoRightPanel {
              title
              description
              tableDescription
              tableColumn {
                title
                subtitle
                value
              }
              bottomText
              solarPanelUse {
                title
                icon
              }
            }
            solarAccountBlockTitle
            solarAccountLeftPanel {
              title
              hint
              priceOffersLeftPanel {
                title
                price
                priceHint
                priceOfferLink
                priceOfferText
                offerCode
                advantages
              }
              priceOffersRightPanel {
                title
                price
                priceHint
                priceOfferLink
                priceOfferText
                offerCode
                advantages
              }
              priceOffersLeftPanelTitle
              priceOffersRightPanelTitle
              leftButtonTitle
              leftButtonLink
              rightButtonTitle
              rightButtonLink
            }
            solarAccountRightPanel {
              title
              description
              leftButtonTitle
              leftButtonLink
              rightButtonTitle
              rightButtonLink
            }
            solarCalculatorBlock {
              title
              photovoltaicsPowerLabel
              annualElectricityProductionEstimateLabel
              annualElectricityConsumptionLabel
              annualElectricityExcessLabel
              expectedAnnualAdvanceLabel
              solarAccountEarningsPrice
              monthlyFee
            }
            SolarUseBlock {
              title
              SolarUseCards {
                title
                description
                icon
              }
            }
            MainVizualLP {
              title
              text
              buttonTitle
              buttonLink
            }
            FAQ {
              title
              questions {
                question
                answer
              }
            }
            CtaVizualLP {
              title
              text
              buttonText
              buttonHref
              linkText
              linkHref
            }
          }
        }
      }
    }
  }
`;

export default RedemptionSolarEnergy;
