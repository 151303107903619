import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { theme } from "../theme";

const SwitchToggle = styled.button`
  display: inline-block;
  padding: 5px;
  font-weight: bold;
  border-radius: 100px;
  color: ${props => props.theme.colors.expertiseGray};
  cursor: ${props => (props.offersLength > 1 ? "pointer" : "default")};
  background-color: ${props => props.backgroundColor};

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
      padding: 8px 18px;
      border-radius: 100px;
      font-size: 14px;
      background-color: ${props => props.backgroundColor};
      color: ${props => props.textColor};

      &.active {
        background-color: ${props => props.theme.colors.green.dark};
        //margin-right: 5px;
        color: ${props => props.theme.colors.white["100"]};
      }
    }
  }
`;

SwitchToggle.displayName = `SwitchToggle`;

SwitchToggle.defaultProps = {
  lineHeight: "1.3",
  backgroundColor: theme.colors.grey.lighter,
  textColor: theme.colors.expertiseGray,
};

SwitchToggle.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
};

export { SwitchToggle };
