import React from "react";
import Tooltip from "rc-tooltip";

const INPUT_VALUE_STEP = 1;

export const PhotovoltaicsFormItem = ({ label, value, units, onChange, isDisabled, error, placeholder, tooltipText }) => (
  <div className="flex flex-col relative">
    <div className="flex justify-between gap-4 items-center">
      <div className="text-mediumForms sm:text-bigForms">{label}</div>
      <div className="flex gap-2 items-center">
        <input
          type="number"
          className={`max-w-[85px] solarCalculatorInput ${isDisabled ? "bg-transparent" : "bg-white"} p-2 sm:pr-0 focus:ring-transparent text-right h-10 ${
            error ? "!border-[#DD2018]" : "!border-white"
          } border-solid text-exploratory-green-400`}
          value={value === null ? "" : value?.toString()}
          step={INPUT_VALUE_STEP}
          onChange={onChange}
          disabled={isDisabled}
          placeholder={placeholder}
        />
        <div className="flex gap-1.5 items-center w-[62px] relative">
          {units}
          {tooltipText ? (
            <Tooltip placement="top" trigger={["click"]} overlay={<span>{tooltipText}</span>}>
              <div className="flex">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8" fill="#ACAEB0" />
                  <path d="M8 12V7" stroke="white" strokeWidth="2" />
                  <path d="M8 6V4" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
    {error ? <div className="inputError">{error}</div> : null}
  </div>
);
