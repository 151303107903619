import React, { useRef, useState } from "react";
import { Text } from "../../style/Text";
import FAQQuestion from "../../style/FAQ/FAQQuestion";
import FAQAnswer from "../../style/FAQ/FAQAnswer";
import FAQItemStyle from "../../style/FAQ/FAQItem";
import { Box } from "../../style/Grid";
import { MarkupToHtml } from "../../helpers/parse";

const FAQItem = ({ question, answer }) => {
  const [questionOpen, setQuestionOpen] = useState(false);
  const answerRef = useRef();
  const questionRef = useRef();

  return (
    <FAQItemStyle open={questionOpen} maxHeight={answerRef?.current?.scrollHeight + questionRef?.current?.scrollHeight}>
      <FAQQuestion open={questionOpen} onClick={() => setQuestionOpen(!questionOpen)} ref={questionRef}>
        <Text>
          <MarkupToHtml>{question}</MarkupToHtml>
        </Text>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.64062 6L10 11.5823L15.3594 6L17 7.70886L10 15L3 7.70886L4.64062 6Z" fill="#2B6E33" />
        </svg>
      </FAQQuestion>
      <FAQAnswer open={questionOpen} maxHeight={answerRef?.current?.scrollHeight} ref={answerRef}>
        <Box>{answer}</Box>
      </FAQAnswer>
    </FAQItemStyle>
  );
};

export { FAQItem };
