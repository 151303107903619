import React from "react";

import { Flex, Wrapper, Container, Box } from "../../style/Grid";
import { Button } from "../../style/Button";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { MarkupToHtml } from "../../helpers/parse";
import { theme } from "../../style/theme";
import { VizualCustomImage } from "../../style/MainVizualLP/MainVizualLP";

const MainVizualLP = ({ title, text, buttonTitle, buttonLink, backgroundImage, customImage = false, customStyle = undefined, customButtons = undefined, footerText = undefined }) =>
{
  const customButtonsJSX = customButtons !== undefined ? customButtons : (<Button variant="primary" as="a" href={buttonLink} minWidth={["210px"]}>
    <MarkupToHtml>{buttonTitle}</MarkupToHtml>
  </Button>)

  return (
    <Wrapper backgroundColor={"resourceGreenLighter"} position="relative" pt={["100vw", null, 0]} zIndex={0} {...customStyle?.wrapper}>
      {customImage && (
        <Box
          display={["none", null, "flex"]}
          position="absolute"
          width="100%"
          height="100%"
          left="auto"
          right="auto"
          maxWidth={theme.sizes.containerMaxWidth}
          paddingTop="32px"
          justifyContent="flex-end"
          paddingRight="15px"
        >
          <VizualCustomImage backgroundImage={backgroundImage} {...customStyle?.customImage} />
        </Box>
      )}

      <Box
        display={customImage ? ["block", null, "none"] : "block"}
        position="absolute"
        left={[0, null, "55%"]}
        top="0"
        height={["100vw", null, "500px"]}
        right="0"
        backgroundPosition={["center", null, "left center"]}
        backgroundSize={["cover", null, "auto 100%"]}
        backgroundRepeat={`no-repeat`}
        backgroundImage={backgroundImage}
        overflow={"hidden"}
        zIndex={1}
        {...customStyle?.bannerImage}
      />

      <Container
        backgroundColor="resourceGreenLighter"
        flexDirection="row"
        minHeight={[null, null, "500px"]}
        alignItems="center"
        {...customStyle?.boxContainer}
      >
        <Flex flexBasis={["100%", null, "60%"]} flexDirection="column" py={"25px"} px={1} {...customStyle?.textWrapper}>
          <Heading
            as="h1"
            fontWeight="700"
            fontSize={["28px", null, "42px"]}
            lineHeight={["38px", null, "52px"]}
            color="exploratoryGreen"
            mb="10px"
            {...customStyle?.heading}
          >
            <MarkupToHtml>{title}</MarkupToHtml>
          </Heading>
          <Text color={"exploratoryGreen"} fontWeight={500} fontSize={["16px", null, "22px"]} lineHeight={["24px", null, "28px"]}>
            <MarkupToHtml>{text}</MarkupToHtml>
          </Text>
          <Flex justifyContent={["center", null, "flex-start"]} width={1} mt={"30px"}>
            {customButtonsJSX}
          </Flex>
        </Flex>
      </Container>

      {footerText ? <Flex backgroundColor="resourceGreenLighter" width="100%" maxWidth={theme.sizes.containerMaxWidth} px={customStyle?.boxContainer?.px} pb={"8px"}>
        <Text maxWidth={["100%", null, "50%"]} color={"exploratoryGreen"} fontSize={["14px", null, "16px"]} lineHeight={["24px", null, "28px"]} mt={["10px", null, "8px"]}>
          <MarkupToHtml>{footerText}</MarkupToHtml>
        </Text>
      </Flex> : null}
    </Wrapper>
  );
};

export default MainVizualLP;
