import React from "react";
import { Button } from "../../style/Button";
import { Flex } from "../../style/Grid";
import { Link } from "gatsby";

export const PhotovoltaicsAccountRightBlock = ({ title, description, rightButtonTitle, leftButtonTitle, rightButtonLink, leftButtonLink }) => {
  const showLeftButton = !!leftButtonTitle;
  const showRightButton = !!rightButtonTitle;

  return (
    <div>
      <h3 className="text-exploratory-green-400 text-center text-H3 mt-6">{title}</h3>
      <div className="text-center">
        <img className="mb-0" src={require("../../images/my-mnd.png").default} width={160} height={122} alt="Stránky MojeMND" />
      </div>
      <div className="text-textGrey my-13" dangerouslySetInnerHTML={{ __html: description }}></div>
      <Flex alignItems="center" mt="0" justifyContent="center" flexDirection={"row"}>
        {(showLeftButton && (
          <Button flex={["0 1 200px"]} height={50} maxWidth={"260px"} to={`${leftButtonLink || ""}`} as={Link}>
            {leftButtonTitle}
          </Button>
        )) ||
          null}
        {(showRightButton && (
          <Button flex={["0 1 200px"]} height={50} maxWidth={"260px"} to={`${rightButtonLink || ""}`} as={Link}>
            {rightButtonTitle}
          </Button>
        )) ||
          null}
      </Flex>
    </div>
  );
};
