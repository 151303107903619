import React from "react";
import { Container } from "../../style/Grid";
import { MarkupToHtml } from "../../helpers/parse";
import { UsageBlockSmallHeading } from "../../style/Photovoltaics/PhotovoltaicsUseBlock";

export const PhotovoltaicsUseBlock = ({ title, solarUseCards }) => {
  const solarPanelUseIconByItemEnum = {
    'billing': <img src={require('../../images/fve-alt.png').default} className="mb-0" width="87.5px" height="56px" alt="vyúčtování" />,
    'supply_point': <img src={require('../../images/pay-another-supply-point.png').default} className="mb-0" width="105px" height="56px" alt="zálohy" />,
    'voucher': <img src={require('../../images/pay-voucher.png').default} className="mb-0" width="114px" height="56px" alt="poukaz" />,
  };
  
  return (
    <Container className="mx-auto" px={[0, null, null, null, 0]}>
      <h2
        className="mb-10 text-exploratory-green-400 text-center w-full text-H3 sm:text-H2"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>

      <div className="flex gap-7 flex-col md:flex-row w-full">
        {solarUseCards ? solarUseCards.map((solarUseCard, index) => (
          <div key={index} className="flex flex-1 flex-col bg-white px-6 py-8 gap-4 shadow-[0_4px_60px_0px_rgba(0,0,0,0.1)]">
            {solarPanelUseIconByItemEnum[solarUseCard.icon]}
            <UsageBlockSmallHeading
              className="mb-0 text-exploratory-green-400 text-[20px]"
              dangerouslySetInnerHTML={{ __html: solarUseCard.title }}
            ></UsageBlockSmallHeading>
            <div className="text-[15px] text-textGrey leading-6">
              <MarkupToHtml>{solarUseCard.description}</MarkupToHtml>
            </div>
          </div>
        )) : null}
      </div>
    </Container>
  );
};
