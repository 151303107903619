import React from "react";
import { Container } from "../style/Grid";

export const DoubleBlock = ({ title, firstBlock, secondBlock, firstBlockFlex, secondBlockFlex }) => (
  <Container className="mx-auto" px={[0, null, null, null, 0]}>
    {title ? <h2 className="text-exploratory-green-400 text-center w-full text-H3 sm:text-H2 mb-8">{title}</h2> : null}
    <div className="flex gap-1 flex-col md:flex-row">
      <div className={`bg-white px-5 sm:px-8 pt-6 pb-8 ${firstBlockFlex ? `flex-${firstBlockFlex}` : 'flex-1'}`}>
        {firstBlock}
      </div>
      <div className={`bg-white px-5 sm:px-8 pt-6 pb-8 ${secondBlockFlex ? `flex-${secondBlockFlex}` : 'flex-1'}`}>
        {secondBlock}
      </div>
    </div>
  </Container>
)

