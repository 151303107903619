import styled from "styled-components";

export const SolarPanelIconsWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const SolarPanelIconWrapper = styled.div`
  &:hover {
    & > img {
      filter: brightness(1.2);
    }
  }
`;
